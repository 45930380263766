window.onload = function() {
	//FastClick.attach(document.body); // to remove delays on clicked links on touch ebabled devices
	// getPrice("dollars", "cents");
};
// to remove delays on clicked links on touch ebabled devices
if ('addEventListener' in document) {
    document.addEventListener('DOMContentLoaded', function() {
        FastClick.attach(document.body);
    }, false);
}

// custom scripts to load

// dismissanble alert boxes
$(document).ready(function() {
    $(".alert-dismissible .close").click( function () {
        $(this).closest(".alert").slideUp(100);
    })
});

// landing page accordion
$(document).ready(function() {
  $('.accord h2').click(function(){
	if( $(this).next('.accord_content').is(':hidden') ) {
	  $('.accord h2').removeClass('active').next('.accord_content').slideUp(200);
	  $(this).toggleClass('active').next('.accord_content').slideDown(200);
	} else
	  if( $(this).next('.accord_content').is(':visible') ) {
	  $(this).removeClass('active').next('.accord_content').slideUp(200);
	}
	return false;
  });
});

// sidebar accordion
$(document).ready(function() {
  $('li.accordion a').click(function(){
	if( $(this).next('p').is(':hidden') ) {
	  $('.accordion a').removeClass('active').next('p').slideUp(200);
	  $(this).toggleClass('active').next('p').slideDown(200);
	} else
	  if( $(this).next('p').is(':visible') ) {
	  $(this).removeClass('active').next('p').slideUp(200);
	}
	return false;
  });
});

//get current year for Footer
var year = new Date().getFullYear();
$('.footer_year').text(year);