function readCookie(name) {
    var nameEQ = escape(name) + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return unescape(c.substring(nameEQ.length, c.length));
    }
    return null;
}
function setCookie(name, value, days) {
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      var expires = "; expires=" + date.toGMTString();
  } else var expires = "";
  var domain = location.hostname == "localhost" ? "" : ";domain=." + location.hostname.split('.').reverse()[1] + "." + location.hostname.split('.').reverse()[0];
  var security = location.hostname == "localhost" ? "" : ";SameSite=None; Secure";
  document.cookie = name + "=" + value + expires + ";path=/" + domain + security;
}
function setCrossCookie(name, value, days) {
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      var expires = "; expires=" + date.toGMTString();
  } else var expires = "";
  var domain = location.hostname == "localhost" ? "" : ";domain=." + location.hostname.split('.').reverse()[1] + "." + location.hostname.split('.').reverse()[0];
  var security = location.hostname == "localhost" ? "" : ";SameSite=None; Secure";
  document.cookie = name + "=" + value + expires + ";path=/" + domain + security;
}
function getQueryString(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
function updateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
    } else {
        return uri + separator + key + "=" + value;
    }
}

var jsonUrl,
    PriceDisplay,
    PriceDollars,
    PriceCents,
    OrigPrice,
    price_lgr,
    price_rr;

var apiUrl = "apiURL_product_replaced_during_build", // this will be dynamically replaced by the build
    price_pids = default_pids_replaced_during_build, // this will be dynamically replaced by the build
    price_pkgids = default_pkgids_replaced_during_build, // this will be dynamically replaced by the build
    default_host = "default_host_replaced_during_build", // this will be dynamically replaced by the build
    price_default_lgr = "default_lgr_replaced_during_build", // this will be dynamically replaced by the build
    price_query_lgr = getQueryString("lgr", window.location.href),
    price_query_rr = getQueryString("rr", window.location.href);

if (price_query_lgr != null && price_query_lgr != "") {
    setCookie('lgr', price_query_lgr.toLowerCase(), 60);
    // console.log(1);
} else if (readCookie("lgr") != null && readCookie("lgr") != "") {
    price_lgr = readCookie("lgr");
    // console.log(2);
} else {
    setCookie("lgr", price_default_lgr.toLowerCase(), 60);
    price_lgr = readCookie("lgr");
    // console.log(3);
}

// setting the cross-domain lgrx cookie for reg3 enhancement
var x_lgr_cookie = readCookie('lgr') !== null ? (readCookie('lgr')).toLowerCase() : null;
if (x_lgr_cookie !== null) {
    setCrossCookie('lgrx', x_lgr_cookie, 60);
}

if (price_query_rr != null && price_query_rr != "") {
    setCookie('rr', price_query_rr, 60);
    price_rr = readCookie("rr");
} else if (readCookie("rr") != null && readCookie("rr") != "") {
    price_rr = readCookie("rr");
}

// appending LGR and RR query string in the registration URL
function modifyRegUrl(real_lgr) {
    $("a[href*='/registration/']").each(function (index, value) {
        price_lgr = real_lgr || readCookie("lgr");
        var href = $(this).attr("href");
        if (getQueryString("lgr", href) != null) {
            href = href;
        }
        else {
            href = href + "&lgr=" + price_lgr;
        }
        if (price_rr && getQueryString("rr", href) != null) {
            href = href;
        }
        else if (price_rr && getQueryString("rr", href) == null) {
            href = href + "&rr=" + price_rr;
        }
        $(this).attr("href", href);
        // console.log(href)
    });
    // $(document).on("touchstart contextmenu mouseover", "a[href*='/registration/']", function(e) {
    //     e.stopPropagation();
    //     price_lgr = real_lgr || readCookie("lgr");
    //     var href = $(this).attr("href");
    //     if (this.href.indexOf("/registration/") != -1) {
    //         if (getQueryString("lgr", href) != null) {
    //             href = href;
    //         }
    //         else {
    //             href = href + "&lgr=" + price_lgr;
    //         }
    //         if (price_rr && getQueryString("rr", href) != null) {
    //             href = href;
    //         }
    //         else if (price_rr && getQueryString("rr", href) == null) {
    //             href = href + "&rr=" + price_rr;
    //         }
    //         $(this).attr("href", href);
    //         // console.log(href)
    //     }
    // });
}

function productApi(pids, callback) {
    price_lgr = readCookie("lgr");
    if (pids.length === 1) {
        jsonUrl = apiUrl +'product/'+ pids.toString() +'/'+ price_lgr + '/'+ default_host + '/' + "?includeRelated=false"; // using API endpoint for multiple PIDs
    }
    else {
        jsonUrl = apiUrl +'products/'+ pids.toString() +'/'+ price_lgr + '/'+ default_host + '/' + "?includeRelated=false"; // using API endpoint for multiple PIDs
    }
    $.getJSON(jsonUrl, function (data) {
        callback(data);
    })
    .fail(function(){
        if (pids.length === 1) {
            jsonUrl = apiUrl +'product/'+ pids.toString() +'/'+ price_default_lgr + '/'+ default_host + '/' + "?includeRelated=false"; // using API endpoint for multiple PIDs
        }
        else {
            jsonUrl = apiUrl +'products/'+ pids.toString() +'/'+ price_default_lgr + '/'+ default_host + '/' + "?includeRelated=false"; // using API endpoint for multiple PIDs
        }
        $.getJSON(jsonUrl, function (data) {
            callback(data);
        });
    });
}
function productApi_default(pids, callback) {
    if (pids.length === 1) {
        jsonUrl = apiUrl +'product/'+ pids.toString() +'/'+ price_default_lgr + '/'+ default_host + '/' + "?includeRelated=false"; // using API endpoint for multiple PIDs
    }
    else {
        jsonUrl = apiUrl +'products/'+ pids.toString() +'/'+ price_default_lgr + '/'+ default_host + '/' + "?includeRelated=false"; // using API endpoint for multiple PIDs
    }
    $.getJSON(jsonUrl, function (data) {
        callback(data);
    });
}

function packageApi(pkgids, callback) {
    price_lgr = readCookie("lgr");
    jsonUrl = apiUrl +'package/'+ pkgids.toString() + '/' + default_host + '/' + price_lgr + '/' + "?includeRelated=false";
    $.getJSON(jsonUrl, function (data) {
        callback(data);
    })
    .fail(function(){
        jsonUrl = apiUrl +'package/'+ pkgids.toString() + '/' + default_host + '/' + price_default_lgr + '/' + "?includeRelated=false";
        $.getJSON(jsonUrl, function (data) {
            callback(data);
        });
    });
}
function packageApi_default(pkgids, callback) {
    jsonUrl = apiUrl +'package/'+ pkgids.toString() + '/' + default_host + '/' + price_default_lgr + '/' + "?includeRelated=false";
    $.getJSON(jsonUrl, function (data) {
        callback(data);
    });
}


/**
 * Returns an array with arrays of the given size.
 *
 * myArray {Array} Array to split
 * chunkSize {Integer} Size of every group
 */
function chunkArray(myArray, size){
    var results = [],
        index = 0;
    while (index < myArray.length) {
        results.push(myArray.slice(index, size + index));
        index += size;
    }
    return results;
}
function removeDuplicates(arr){
    var unique_array = [];
    for(var i = 0;i < arr.length; i++){
        if(unique_array.indexOf(arr[i]) == -1){
            unique_array.push(arr[i]);
        }
    }
    return unique_array;
}

function displayPrice(data) {
    //console.log(data)
    function showPrice(priceData) {
        PriceDisplay = priceData.price_per_unit.amount;
        PriceDollars = priceData.price_per_unit.display_dollars;
        PriceCents = priceData.price_per_unit.display_cents;
        OrigPrice = Math.round((PriceDisplay + (PriceDisplay * .2)).toFixed(0) / 5) * 5;
        $('.dollars[data-pid='+priceData.id+']').text(PriceDollars);
        $('.cents[data-pid='+priceData.id+']').text(PriceCents);
        $('.fullprice[data-pid='+priceData.id+']').text(PriceDisplay);
        $('.origprice[data-pid='+priceData.id+']').text(OrigPrice);
        $('.dollars[data-pid='+priceData.id+']').parent(".price").addClass("shown");
    }
    if (Array.isArray(data)) {
        for (var i = 0; i < data.length; i++) {
            if (data[i].price_per_unit != null){
                showPrice(data[i]);
            }
        }
    }
    else {
        if (data.price_per_unit != null){
            showPrice(data);
        }
    }
}
function displayPackagePrice(data) {
    //console.log(data)
    if (data.price_per_unit != null){
        PriceDisplay = data.price_per_unit.amount;
        PriceDollars = data.price_per_unit.display_dollars;
        PriceCents = data.price_per_unit.display_cents;
        OrigPrice = Math.round((PriceDisplay + (PriceDisplay * .2)).toFixed(0) / 5) * 5;
        $('.dollars[data-pkgid='+data.id+']').text(PriceDollars);
        $('.cents[data-pkgid='+data.id+']').text(PriceCents);
        $('.fullprice[data-pkgid='+data.id+']').text(PriceDisplay);
        $('.origprice[data-pkgid='+data.id+']').text(OrigPrice);
        $('.dollars[data-pkgid='+data.id+']').parent(".price").addClass("shown");
    }
}

function addPriceToSession(data, pkgflag) {
    var product_price = {
        id: data.id,
        amount: data.price_per_unit.amount,
        display_dollars: data.price_per_unit.display_dollars,
        display_cents: data.price_per_unit.display_cents,
        lgr: data.lgr
    };
    var prefix = pkgflag ? 'pkgid_' : 'pid_';
    sessionStorage.setItem(prefix + data.id, JSON.stringify(product_price))
}

function getProductPriceDefault(pids) {
    var clean_pids = removeDuplicates(pids);
    var chunk_pids = chunkArray(clean_pids, 30);
    for (var i = 0; i < chunk_pids.length; i++) {
        var current_pids = chunk_pids[i];
        productApi_default(chunk_pids[i], function(data){
            if (data !== undefined && current_pids.length > 1) {
                for (var i = 0; i < data.length; i++) {
                    if (data[i].price_per_unit != null){
                        addPriceToSession(data[i]);
                    } else {
                        console.log('Product Not in Default LGR:' + data[i].id);
                    }
                }
                sessionStorage.setItem('asc_pricing', true);
                displayPrice(data);
            }
            else if (data !== undefined && current_pids.length === 1) {
                if (data.price_per_unit != null){
                    addPriceToSession(data);
                } else {
                    getProductPriceDefault(pids);
                }
                sessionStorage.setItem('asc_pricing', true);
                displayPrice(data);
            }
            else {
                console.log("API is down or something else is going on...");
            }
        });
    }
}

function getProductPrice(pids) {
    var chunk_pids = chunkArray(pids, 30),
        counter = 0;
    for (var i = 0; i < chunk_pids.length; i++) {
        var current_pids = chunk_pids[i];
        productApi(current_pids, function(data){
            // console.log(current_pids.length)
            if (data !== undefined && current_pids.length > 1) {
                var new_pids = [];
                for (var j = 0; j < data.length; j++) {
                    if (data[j].price_per_unit != null){
                        addPriceToSession(data[j]);
                    } else {
                        new_pids.push(data[j].id);
                    }
                }
                // sessionStorage.setItem('new_price_pids', JSON.stringify(new_pids));
                if (new_pids.length > 0) {
                    getProductPriceDefault(new_pids);
                }
                sessionStorage.setItem('asc_pricing', true);
                displayPrice(data);
            }
            else if (data !== undefined && current_pids.length === 1) {
                if (data.price_per_unit != null){
                    addPriceToSession(data);
                } else {
                    getProductPriceDefault(pids);
                }
                sessionStorage.setItem('asc_pricing', true);
                displayPrice(data);
            }
            else {
                console.log("API is down or something else is going on...");
            }
        });
    }
}

function getPackagePrice(pkgids) {
    for (var i = 0; i < pkgids.length; i++) {
        packageApi(pkgids[i], function(data){
            //console.log(data);
            if (data !== undefined) {
                if (data.price_per_unit != null){
                    addPriceToSession(data, true);
                }
                sessionStorage.setItem('asc_pricing', true);
                displayPackagePrice(data);
            }
            else {
                console.log("API is down or something else is going on...");
            }
        });
    }
}

// creating init funtion in case pricing needs to be called independently from DOM ready
function pricingInit() {
    if (sessionStorage.getItem('asc_pricing')) {
        for (var i = 0; i < price_pids.length; i++) {
            if (sessionStorage.getItem('pid_'+price_pids[i])) {
                //console.log(JSON.parse(sessionStorage.getItem('pid_' + price_pids[i])));
                PriceDisplay = JSON.parse(sessionStorage.getItem('pid_' + price_pids[i])).amount;
                PriceDollars = JSON.parse(sessionStorage.getItem('pid_' + price_pids[i])).display_dollars;
                PriceCents = JSON.parse(sessionStorage.getItem('pid_' + price_pids[i])).display_cents;
                OrigPrice = Math.round((PriceDisplay + (PriceDisplay * .2)).toFixed(0) / 5) * 5;
                $('.dollars[data-pid='+price_pids[i]+']').text(PriceDollars);
                $('.cents[data-pid='+price_pids[i]+']').text(PriceCents);
                $('.fullprice[data-pid='+price_pids[i]+']').text(PriceDisplay);
                $('.origprice[data-pid='+price_pids[i]+']').text(OrigPrice);
                $('.dollars[data-pid='+price_pids[i]+']').parent(".price").addClass("shown");

            }
        }
        for (var i = 0; i < price_pkgids.length; i++) {
            if (sessionStorage.getItem('pkgid_'+price_pkgids[i])) {
                //console.log(JSON.parse(sessionStorage.getItem('pkgid_' + price_pkgids[i])));
                PriceDisplay = JSON.parse(sessionStorage.getItem('pkgid_' + price_pkgids[i])).amount;
                PriceDollars = JSON.parse(sessionStorage.getItem('pkgid_' + price_pkgids[i])).display_dollars;
                PriceCents = JSON.parse(sessionStorage.getItem('pkgid_' + price_pkgids[i])).display_cents;
                OrigPrice = Math.round((PriceDisplay + (PriceDisplay * .2)).toFixed(0) / 5) * 5;
                $('.dollars[data-pkgid='+price_pkgids[i]+']').text(PriceDollars);
                $('.cents[data-pkgid='+price_pkgids[i]+']').text(PriceCents);
                $('.fullprice[data-pkgid='+price_pkgids[i]+']').text(PriceDisplay);
                $('.origprice[data-pkgid='+price_pkgids[i]+']').text(OrigPrice);
                $('.dollars[data-pkgid='+price_pkgids[i]+']').parent(".price").addClass("shown");
            }
        }
        getProductPrice(price_pids); // this will make sure that the price is being updated from API even if there's stored pricing
        getPackagePrice(price_pkgids); // this will make sure that the price is being updated from API even if there's stored pricing
    } else {
        getProductPrice(price_pids);
        getPackagePrice(price_pkgids);
    }
    modifyRegUrl();
}
pricingInit();
